
import { computed, defineComponent, ref } from "vue";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CTabs, { CTabItem } from "@/components/Tabs.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter } from "@/libs/utils";

const VAgendaList = defineComponent({
  name: "VAgendaList",
  components: { CTabs, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de agendas | obmed" });
    const route = useRoute();
    const router = useRouter();

    const { hasFilters } = useFilter();
    const tabs: CTabItem[] = [
      { label: "Consultas", icon: "fas fa-stethoscope", to: { name: "agenda-consultation" } },
      { label: "Exames de Imagem", icon: "fas fa-heart", to: { name: "agenda-exam" } },
      { label: "Exames Laboratoriais", icon: "fas fa-flask", to: { name: "agenda-laboratory" } },
      { label: "Procedimentos", icon: "fas fa-band-aid", to: { name: "agenda-procedure" } },
      { label: "Pacotes de Serviços", icon: "fas fa-boxes-stacked", to: { name: "agenda-service" } },
    ];
    const current = ref(tabs.find((item) => item.to.name === route.name?.toString().replace("-filter", "")) || tabs[0]);
    const filterRoute = computed(() => {
      try {
        const { name } = router.resolve({
          name: `${String(route.name).replace("-filter", "")}-filter`,
        });
        return name;
      } catch {
        return `${String(route.name).replace("-filter", "")}`;
      }
    });
    const breadcrumb = computed(() => [
      {
        label: "Agendas",
        icon: "fas fa-calendar-alt mr-2",
        to: { name: "agenda-list", query: route.query },
      },
      {
        label: current.value.label,
        icon: `${current.value.icon} mr-2`,
        to: { name: current.value.to.name, query: route.query },
      },
    ]);

    if (hasFilters.value) router.push({ name: filterRoute.value?.toString(), query: route.query });

    return { tabs, current, breadcrumb, filterRoute, hasFilters };
  },
});

export default VAgendaList;
